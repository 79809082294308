<template>
  <v-card>
    <v-card-title>
      <v-col>
        <span class="headline">Editar/agregar abonos de {{ cliente }}</span>
      </v-col>
    </v-card-title>
    <v-card-text>
      <v-container>
        <template v-if="nAbono == true">
          <v-list>
            <v-list-group
              v-for="(data, index) in listaPagos"
              v-bind:key="data + index"
            >
              <template v-slot:activator>
                <v-list-item-title>Abono {{ index + 1 }}</v-list-item-title>
              </template>
              <v-list-item>
                <v-row>
                  <v-col cols="12" md="2">
                    <v-select
                      :items="estadoP"
                      item-text="nombre"
                      item-value="nombre"
                      v-model="data.pago"
                      placeholder="Tipo de pago"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-select
                      :items="mPagos"
                      item-text="nombre"
                      item-value="nombre"
                      v-model="data.modoPago"
                      placeholder="Modo de pago"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      :value="data.fechaAcordada | formatDate"
                      disabled
                      label="Fecha acordada"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-menu
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="data.fechaPago | formatDate"
                          label="Fecha de pago"
                          readonly
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        locale="ES"
                        v-model="data.fechaPago"
                        no-title
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3">
                    <money
                      class="money"
                      v-model="data.cantidad"
                      label="Cantidad"
                      v-bind="money"
                    ></money>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item>
                <v-row>
                  <v-col
                    cols="12"
                    md="5"
                    v-bind:style="{ 'margin-top': '18px' }"
                  >
                    <label v-if="data.comprobante == ''">
                      Cargar comprobante:
                      <input
                        id="files"
                        ref="file"
                        @change="getFile"
                        type="file"
                      />
                    </label>
                    <v-btn
                      v-if="data.comprobante != ''"
                      small
                      class="ma-2"
                      color="primary"
                      @click="download(data.comprobante)"
                    >
                      Ver comprobante
                      <v-icon right>mdi-download</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col v-if="base64 != null" cols="12" md="4">
                    <v-text-field
                      v-model="data.noComprobante"
                      label="No. de comprobante"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="base64 == null" cols="12" md="4"></v-col>
                  <v-col
                    cols="12"
                    md="1"
                    v-bind:style="{ 'margin-top': '18px' }"
                  >
                    <v-btn
                      small
                      :color="buscando == false ? 'white' : 'grey'"
                      @click="deleteCuota(data)"
                      >Eliminar
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    md="1"
                    v-bind:style="{ 'margin-top': '18px' }"
                  >
                    <v-btn
                      small
                      :disabled="buscando"
                      color="primary"
                      @click="buscando == false ? editPago(data) : ''"
                    >
                      Guardar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list-group>
          </v-list>
          <v-list>
            <v-list-group>
              <template v-slot:activator>
                <v-list-item-title>Nuevo abono</v-list-item-title>
              </template>
              <v-list-item>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-select
                      :items="estadoP"
                      item-text="nombre"
                      item-value="nombre"
                      v-model="pagoExtra.pago"
                      placeholder="Tipo de pago"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      item-text="nombre"
                      item-value="nombre"
                      :items="mPagos"
                      v-model="pagoExtra.modoPago"
                      placeholder="Modo de pago"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <money
                      class="money"
                      v-model="pagoExtra.cantidad"
                      label="Cantidad"
                      v-bind="money"
                    ></money>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-menu
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="pagoExtra.fechaPago | formatDate"
                          label="Fecha de pago"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="ES"
                        v-model="pagoExtra.fechaPago"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-row>
                <v-col cols="12" md="5" v-bind:style="{ 'margin-top': '18px' }">
                  <label>
                    Cargar comprobante:
                    <input
                      id="files"
                      ref="fileE"
                      @change="getFileE"
                      type="file"
                    />
                  </label>
                </v-col>
                <v-col cols="12" md="4" v-if="base64E != null">
                  <v-text-field
                    v-model="pagoExtra.noComprobante"
                    label="No. de comprobante"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" v-if="base64E == null"></v-col>
                <v-col cols="12" md="1" v-bind:style="{ 'margin-top': '18px' }">
                  <v-btn
                    :disabled="buscando"
                    color="primary"
                    small
                    @click="buscando == false ? nuevaCuota(pagoExtra) : ''"
                    >Guardar
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-group>
          </v-list>
        </template>
        <template v-if="nAbono == false">
          <v-list>
            <v-list-group>
              <template v-slot:activator>
                <v-list-item-title
                  >Abono #{{ listaPagos.noPago }}</v-list-item-title
                >
              </template>
              <v-list-item>
                <v-row>
                  <v-col col="12" md="2">
                    <v-select
                      :items="estadoP"
                      item-text="nombre"
                      item-value="nombre"
                      v-model="listaPagos.pago"
                      placeholder="Tipo de pago"
                    ></v-select>
                  </v-col>
                  <v-col col="12" md="2">
                    <v-select
                      :items="mPagos"
                      item-text="nombre"
                      item-value="nombre"
                      v-model="listaPagos.modoPago"
                      placeholder="Modo de pago"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      :value="listaPagos.fechaAcordada | formatDate"
                      disabled
                      label="Fecha acordada"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col col="12" md="2">
                    <v-menu
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="listaPagos.fechaPago | formatDate"
                          label="Fecha de pago"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="ES"
                        v-model="listaPagos.fechaPago"
                        no-title
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col col="12" md="3">
                    <money
                      class="money"
                      v-bind="money"
                      v-model="listaPagos.cantidad"
                      label="Cantidad"
                    ></money>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item>
                <v-row>
                  <v-col
                    cols="12"
                    md="5"
                    v-bind:style="{ 'margin-top': '18px' }"
                  >
                    <label>
                      Cargar comprobante:
                      <input
                        id="filesI"
                        ref="fileI"
                        @change="getFileI"
                        type="file"
                      />
                    </label>
                  </v-col>
                  <v-col cols="12" md="4" v-if="base64I != null">
                    <v-text-field
                      v-model="listaPagos.noComprobante"
                      label="No. de comprobante"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" v-if="base64I == null"></v-col>
                  <v-col
                    cols="12"
                    md="1"
                    v-bind:style="{ 'margin-top': '18px' }"
                  >
                    <v-btn
                      small
                      :disabled="buscando"
                      color="primary"
                      @click="buscando == false ? editPagoI(listaPagos) : ''"
                      >Guardar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list-group>
          </v-list>
          <v-list></v-list>
        </template>
        <template>
          <v-row v-bind:style="{ 'justify-content': 'space-around' }">
            <v-col cols="12" sm="2" md="3">
              <h4>Valor acordado: {{ valorAcordado | currency }}</h4>
            </v-col>
            <v-col cols="12" sm="2" md="3">
              <h4 class="primary--text">
                Valor recaudado : {{ valorRecaudado | currency }}
              </h4>
            </v-col>
            <v-col cols="12" sm="2" md="3">
              <h4 class="red--text">
                Valor Restante: {{ valorRestante | currency }}
              </h4>
            </v-col>
          </v-row>
        </template>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="accent" text @click="cerrar">cerrar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import firebase from "firebase";
import { host } from "../Tools/variables";
import axios from "axios";
import data from "../Tools/data";

export default {
  name: "PagosAbonos",
  props: [
    "cliente",
    "listaPagos",
    "cuotasTotales",
    "ventaId",
    "valorAcordado",
    "valorRecaudado",
    "valorRestante",
    "tipo",
    "trabajo",
  ],
  data() {
    return {
      base64: null,
      files: [],
      buscando: false,
      nAbono: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "$ ",
        suffix: "",
        precision: 1,
        masked: false,
      },
      pagoExtra: {
        modoPago: null,
        cantidad: null,
        pago: null,
        estado: null,
        fechaPago: null,
      },
      base64E: null,
      filesE: [],
      base64I: null,
      filesI: [],
      dataMpago: [],
      mPagos: [],
      estadoP: [],
    };
  },

  async created() {
    // MODOS DE PAGOS
    if (this.$store.state.modosPago.length == 0) {
      this.mPagos = await data.dataList(
        "/modosPago",
        "lista",
        "listar",
        "modosPago"
      );
    } else {
      this.mPagos = this.$store.state.modosPago;
    }
    // ESTADOS DE PAGOS
    if (this.$store.state.estadosPagos.length == 0) {
      this.estadoP = await data.dataList(
        "/estadosPago",
        "lista",
        "listar",
        "estadosPagos"
      );
    } else {
      this.estadoP = this.$store.state.estadosPagos;
    }
  },

  mounted() {
    if (this.tipo == "normal") {
      this.nAbono = true;
    }
  },

  methods: {
    getFile() {
      this.files = [];
      this.files = this.$refs.file[0].files[0];
      this.convertFile(this.files);
    },

    convertFile(d) {
      var url = d;
      var reader = new FileReader();
      reader.readAsDataURL(url);
      reader.onload = () => {
        this.base64 = reader.result;
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },

    download(content) {
      window.open(content, "_blank");
    },

    getFileE() {
      this.filesE = [];
      this.filesE = this.$refs.fileE.files[0];
      this.convertFileE(this.filesE);
    },

    convertFileE(d) {
      var url = d;
      var reader = new FileReader();
      reader.readAsDataURL(url);
      reader.onload = () => {
        this.base64E = reader.result;
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },

    getFileI() {
      this.filesI = [];
      this.filesI = this.$refs.fileI.files[0];
      this.convertFileI(this.filesI);
    },

    convertFileI(d) {
      var url = d;
      var reader = new FileReader();
      reader.readAsDataURL(url);
      reader.onload = () => {
        this.base64I = reader.result;
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },

    editPago(pago) {
      this.buscando = true;
      if (this.buscando == true) {
        var entra = false;
        if (
          this.cuotasTotales == pago.noPago &&
          this.valorAcordado > this.valorRecaudado + pago.cantidad &&
          pago.pago != "PAGO NA"
        ) {
          this.$alert(
            `Debes crear un nuevo abono con el sando pendiente  ${
              this.valorRestante - pago.cantidad
            } y poner en este estado PAGO NA`
          );
          this.buscando == false;
        } else if (
          this.cuotasTotales == pago.noPago &&
          this.valorAcordado > this.valorRecaudado + pago.cantidad &&
          pago.pago == "PAGO NA"
        ) {
          this.$alert(
            `No olvides que debes crear un nuevo abono con el sando pendiente  ${
              this.valorRestante - pago.cantidad
            }`
          );
          entra = true;
        } else {
          entra = true;
        }
        if (entra) {
          var fecha = this.$moment(pago.fechaPago).add(5, "h");
          firebase
            .auth()
            .currentUser.getIdToken(/* forceRefresh */ true)
            .then((idToken) => {
              const fd = new FormData();
              fd.append("token", idToken);
              fd.append("tipo", "registrar");
              fd.append("numeroPago", pago.noPago);
              fd.append("idVenta", pago.ventaId);
              fd.append("cantidad", pago.cantidad);
              fd.append("modoPago", pago.modoPago);
              fd.append("trabajo", this.trabajo ? "SI" : "NO");
              fd.append("tipoPago", pago.pago);
              fd.append(
                "soportePago",
                pago.comprobante == "" ? this.base64 : ""
              );
              fd.append("noComprobante", pago.noComprobante);
              fd.append("fechaAcordada", fecha);
              axios.post(host + "/pagos", fd).then((result) => {
                // console.log("editar el pago" + JSON.stringify(result));
                let data = result.data;
                this.buscando = false;
                if (data.text == "OK") {
                  this.$emit("toggle", pago);
                } else {
                  this.$alert(data.text);
                }
              });
            })
            .catch(function (error) {
              this.$alert("Error " + error.message);
            });
        }
      }
    },

    editPagoI(pago) {
      this.buscando = true;
      if (this.buscando == true) {
        var entra = false;
        if (
          this.cuotasTotales == pago.noPago &&
          this.valorAcordado > this.valorRecaudado + pago.cantidad &&
          pago.pago != "PAGO NA"
        ) {
          this.$alert(
            `Debes crear un nuevo abono con el sando pendiente  ${
              this.valorRestante - pago.cantidad
            } y poner en este estado PAGO NA`
          );
          this.buscando == false;
        } else if (
          this.cuotasTotales == pago.noPago &&
          this.valorAcordado > this.valorRecaudado + pago.cantidad &&
          pago.pago == "PAGO NA"
        ) {
          this.$alert(
            `No olvides que debes crear un nuevo abono con el sando pendiente  ${
              this.valorRestante - pago.cantidad
            }`
          );
          entra = true;
        } else {
          entra = true;
        }
        if (entra) {
          var fecha = this.$moment(pago.fechaPago).add(5, "h");
          firebase
            .auth()
            .currentUser.getIdToken(/* forceRefresh */ true)
            .then((idToken) => {
              const fd = new FormData();
              fd.append("token", idToken);
              fd.append("tipo", "registrar");
              fd.append("numeroPago", pago.noPago);
              fd.append("idVenta", pago.ventaId);
              fd.append("cantidad", pago.cantidad);
              fd.append("modoPago", pago.modoPago);
              fd.append("trabajo", this.trabajo ? "SI" : "NO");
              fd.append("tipoPago", pago.pago);
              fd.append(
                "soportePago",
                pago.comprobante == "" ? this.base64I : ""
              );
              fd.append("noComprobante", pago.noComprobante);
              fd.append("fechaAcordada", fecha);
              axios.post(host + "/pagos", fd).then((result) => {
                let data = result.data;
                this.buscando = false;
                if (data.text == "OK") {
                  this.$emit("toggle", pago);
                } else {
                  this.$alert(data.text);
                }
              });
            })
            .catch(function (error) {
              this.$alert("Error " + error.message);
            });
        }
      }
    },

    deleteCuota(info) {
      this.$confirm({
        message: "Deseas eliminar este abono?, algunos valores se recalcularán",
        button: {
          no: "Cancelar",
          yes: "Aceptar",
        },
        callback: (confirm) => {
          if (confirm == true) {
            firebase
              .auth()
              .currentUser.getIdToken(/* forceRefresh */ true)
              .then((idToken) => {
                const fd = new FormData();
                fd.append("token", idToken);
                fd.append("tipo", "eliminar");
                fd.append("pagoId", info._id);
                axios.post(host + "/pagos", fd).then((result) => {
                  let data = result.data;
                  if (data.text == "OK") {
                    this.$emit("toggle", true);
                  } else {
                    this.$alert("No se pudo realizar el ajuste del pago");
                  }
                });
              })
              .catch((error) => {
                this.$alert("Error " + error.message);
              });
          }
        },
      });
    },

    nuevaCuota() {
      this.buscando = true;
      if (this.buscando == true) {
        var fecha2 = this.$moment(this.pagoExtra.fechaPago).add(5, "h");
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            const fd = new FormData();
            fd.append("token", idToken);
            fd.append("tipo", "pago_extra");
            fd.append("idVenta", this.ventaId);
            fd.append("cantidad", this.pagoExtra.cantidad);
            fd.append("modoPago", this.pagoExtra.modoPago);
            fd.append("tipoPago", this.pagoExtra.pago);
            fd.append("soportePago", this.base64E);
            fd.append("noComprobante", this.pagoExtra.noComprobante);
            fd.append("fechaPago", fecha2);
            axios.post(host + "/pagos", fd).then((result) => {
              let data = result.data;
              this.buscando = false;
              if (data.text == "OK") {
                this.$emit("toggle", this.pagoExtra);
                this.pagoExtra = {
                  modoPago: null,
                  cantidad: null,
                  pago: null,
                  estado: null,
                  fechaPago: null,
                };
              } else {
                this.$alert(data.text);
              }
            });
          })
          .catch((error) => {
            this.$alert("Error " + error.message);
          });
      }
    },

    cerrar() {
      this.$emit("toggle", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-money {
  outline: none !important;
  border: none;
  border-bottom: 1px solid rgb(118, 118, 118);
  padding: 4px 0px;
  margin-top: 18px;
  color: grey;
  font-size: 18px;
  width: 100%;
}
</style>